/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        // toggles hamburger and nav open and closed states
        var removeClass = true;
        $(".hamburger").click(function () {
          $(".hamburger").toggleClass('is-active');
          $("#sideNav").toggleClass('sideNav-open');
          $(".sideNavBody").toggleClass('sideNavBody-push');
          removeClass = false;
        });

        $(".sideNav").click(function() {
          removeClass = false;
        });

        document.addEventListener('touchstart', function(e) {
          if (removeClass && !$(e.target).hasClass('sideNav') && $('.sideNav').has($(e.target)).length === 0) {
             $(".hamburger").removeClass('is-active');
             $("#sideNav").removeClass('sideNav-open');
             $(".sideNavBody").removeClass('sideNavBody-push');
          }
          removeClass = true;
        }, false);


        // Scroll up button
        $(window).scroll(function () {
          if ($(this).scrollTop() > 100) {
            $('.scrollup').fadeIn();
          } else {
            $('.scrollup').fadeOut();
          }
        });

        $('.scrollup').click(function () {
          $("html, body").animate({
            scrollTop: 0
          }, 600);
          return false;
        });


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'contact_us': {
      init: function() {
        // JavaScript to be fired on the about us page

        document.initMap = function() {
             var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
             var isDraggable = w > 480 ? true : false;
             var map = new google.maps.Map(document.getElementById('serviceMap'), {
               zoom: 6,
               scrollwheel: false,
               draggable: isDraggable,
               center: {lat: 45.5000, lng: -69.0000},
               disableDefaultUI: true,
               mapTypeId: google.maps.MapTypeId.ROAD
             });

             var maineMapPolygon = [
               new google.maps.LatLng(45.305803,-71.092529),
               new google.maps.LatLng(45.328979,-70.993652),
               new google.maps.LatLng(45.313529,-70.927734),
               new google.maps.LatLng(45.228481,-70.850830),
               new google.maps.LatLng(45.383019,-70.828857),
               new google.maps.LatLng(45.413876,-70.784912),
               new google.maps.LatLng(45.390735,-70.642090),
               new google.maps.LatLng(45.490946,-70.718994),
               new google.maps.LatLng(45.598666,-70.653076),
               new google.maps.LatLng(45.667805,-70.532227),
               new google.maps.LatLng(45.729191,-70.389404),
               new google.maps.LatLng(45.805829,-70.411377),
               new google.maps.LatLng(45.912944,-70.246582),
               new google.maps.LatLng(45.989329,-70.301514),
               new google.maps.LatLng(46.088472,-70.279541),
               new google.maps.LatLng(46.141783,-70.246582),
               new google.maps.LatLng(46.202646,-70.279541),
               new google.maps.LatLng(46.362093,-70.136719),
               new google.maps.LatLng(46.407564,-70.059814),
               new google.maps.LatLng(46.679594,-69.993896),
               new google.maps.LatLng(47.442950,-69.213867),
               new google.maps.LatLng(47.413220,-69.038086),
               new google.maps.LatLng(47.234490,-69.038086),
               new google.maps.LatLng(47.174778,-68.906250),
               new google.maps.LatLng(47.249407,-68.598633),
               new google.maps.LatLng(47.271775,-68.576660),
               new google.maps.LatLng(47.301585,-68.477783),
               new google.maps.LatLng(47.284819,-68.414612),
               new google.maps.LatLng(47.346267,-68.378906),
               new google.maps.LatLng(47.359293,-68.293762),
               new google.maps.LatLng(47.346267,-68.225098),
               new google.maps.LatLng(47.282956,-68.112488),
               new google.maps.LatLng(47.238219,-68.013611),
               new google.maps.LatLng(47.212106,-67.988892),
               new google.maps.LatLng(47.197178,-67.953186),
               new google.maps.LatLng(47.165442,-67.933960),
               new google.maps.LatLng(47.126213,-67.887268),
               new google.maps.LatLng(47.098175,-67.879028),
               new google.maps.LatLng(47.066380,-67.788391),
               new google.maps.LatLng(45.935871,-67.777405),
               new google.maps.LatLng(45.918677,-67.747192),
               new google.maps.LatLng(45.870888,-67.804871),
               new google.maps.LatLng(45.824971,-67.752686),
               new google.maps.LatLng(45.798170,-67.802124),
               new google.maps.LatLng(45.756026,-67.802124),
               new google.maps.LatLng(45.729191,-67.780151),
               new google.maps.LatLng(45.665886,-67.777405),
               new google.maps.LatLng(45.686996,-67.741699),
               new google.maps.LatLng(45.675482,-67.678528),
               new google.maps.LatLng(45.619801,-67.656555),
               new google.maps.LatLng(45.617880,-67.612610),
               new google.maps.LatLng(45.585212,-67.500000),
               new google.maps.LatLng(45.598666,-67.491760),
               new google.maps.LatLng(45.604431,-67.450562),
               new google.maps.LatLng(45.562141,-67.420349),
               new google.maps.LatLng(45.539060,-67.428589),
               new google.maps.LatLng(45.504422,-67.414856),
               new google.maps.LatLng(45.487095,-67.497253),
               new google.maps.LatLng(45.423516,-67.469788),
               new google.maps.LatLng(45.381090,-67.420349),
               new google.maps.LatLng(45.286482,-67.480774),
               new google.maps.LatLng(45.193651,-67.428589),
               new google.maps.LatLng(45.127805,-67.343445),
               new google.maps.LatLng(45.147179,-67.302246),
               new google.maps.LatLng(45.189780,-67.283020),
               new google.maps.LatLng(45.168484,-67.228088),
               new google.maps.LatLng(45.164611,-67.156677),
               new google.maps.LatLng(44.906469,-66.964417),
               new google.maps.LatLng(44.853922,-66.978149),
               new google.maps.LatLng(44.830552,-66.969910),
               new google.maps.LatLng(44.805224,-66.969910),
               new google.maps.LatLng(44.660839,-67.159424),
               new google.maps.LatLng(44.627619,-67.261047),
               new google.maps.LatLng(44.600246,-67.283020),
               new google.maps.LatLng(44.592423,-67.401123),
               new google.maps.LatLng(44.555249,-67.489014),
               new google.maps.LatLng(44.490628,-67.516479),
               new google.maps.LatLng(44.453389,-67.571411),
               new google.maps.LatLng(44.400430,-67.898254),
               new google.maps.LatLng(44.329743,-68.049316),
               new google.maps.LatLng(44.237328,-68.189392),
               new google.maps.LatLng(44.101393,-68.334961),
               new google.maps.LatLng(44.012571,-68.634338),
               new google.maps.LatLng(44.040219,-68.862305),
               new google.maps.LatLng(43.977005,-69.131470),
               new google.maps.LatLng(43.921637,-69.213867),
               new google.maps.LatLng(43.913723,-69.268799),
               new google.maps.LatLng(43.828583,-69.507751),
               new google.maps.LatLng(43.779027,-69.653320),
               new google.maps.LatLng(43.699651,-69.845581),
               new google.maps.LatLng(43.713550,-70.002136),
               new google.maps.LatLng(43.564472,-70.199890),
               new google.maps.LatLng(43.532620,-70.312500),
               new google.maps.LatLng(43.530629,-70.359192),
               new google.maps.LatLng(43.470861,-70.372925),
               new google.maps.LatLng(43.415024,-70.372925),
               new google.maps.LatLng(43.359135,-70.430603),
               new google.maps.LatLng(43.341160,-70.474548),
               new google.maps.LatLng(43.341160,-70.529480),
               new google.maps.LatLng(43.251204,-70.595398),
               new google.maps.LatLng(43.213183,-70.573425),
               new google.maps.LatLng(43.127048,-70.631104),
               new google.maps.LatLng(43.054841,-70.691528),
               new google.maps.LatLng(42.861873,-70.826111),
               new google.maps.LatLng(42.871938,-70.946960),
               new google.maps.LatLng(42.863886,-71.012878),
               new google.maps.LatLng(42.825624,-71.054077),
               new google.maps.LatLng(42.813537,-71.122742),
               new google.maps.LatLng(42.807492,-71.150208),
               new google.maps.LatLng(42.755080,-71.180420),
               new google.maps.LatLng(42.740961,-71.224365),
               new google.maps.LatLng(42.718768,-71.273804),
               new google.maps.LatLng(42.704641,-71.301270),
               new google.maps.LatLng(42.724821,-72.463074),
               new google.maps.LatLng(42.773227,-72.498779),
               new google.maps.LatLng(42.815551,-72.537231),
               new google.maps.LatLng(42.879990,-72.556458),
               new google.maps.LatLng(42.910172,-72.523499),
               new google.maps.LatLng(42.954412,-72.531738),
               new google.maps.LatLng(42.990586,-72.449341),
               new google.maps.LatLng(43.052834,-72.457581),
               new google.maps.LatLng(43.129052,-72.435608),
               new google.maps.LatLng(43.257206,-72.427368),
               new google.maps.LatLng(43.367122,-72.408142),
               new google.maps.LatLng(43.462887,-72.386169),
               new google.maps.LatLng(43.532620,-72.405396),
               new google.maps.LatLng(43.604262,-72.331238),
               new google.maps.LatLng(43.685750,-72.292786),
               new google.maps.LatLng(43.777044,-72.191162),
               new google.maps.LatLng(43.856316,-72.180176),
               new google.maps.LatLng(43.925594,-72.130737),
               new google.maps.LatLng(43.988862,-72.097778),
               new google.maps.LatLng(44.069827,-72.053833),
               new google.maps.LatLng(44.164474,-72.053833),
               new google.maps.LatLng(44.217647,-72.059326),
               new google.maps.LatLng(44.247167,-72.048340),
               new google.maps.LatLng(44.282570,-72.062073),
               new google.maps.LatLng(44.314023,-72.029114),
               new google.maps.LatLng(44.345458,-71.911011),
               new google.maps.LatLng(44.347422,-71.828613),
               new google.maps.LatLng(44.398467,-71.787415),
               new google.maps.LatLng(44.420050,-71.707764),
               new google.maps.LatLng(44.467111,-71.628113),
               new google.maps.LatLng(44.504341,-71.589661),
               new google.maps.LatLng(44.555249,-71.584167),
               new google.maps.LatLng(44.596335,-71.531982),
               new google.maps.LatLng(44.627619,-71.553955),
               new google.maps.LatLng(44.678419,-71.575928),
               new google.maps.LatLng(44.752585,-71.622620),
               new google.maps.LatLng(44.865603,-71.526489),
               new google.maps.LatLng(44.920084,-71.493530),
               new google.maps.LatLng(44.988113,-71.531982),
               new google.maps.LatLng(45.028892,-71.493530),
               new google.maps.LatLng(45.059942,-71.493530),
               new google.maps.LatLng(45.098730,-71.457825),
               new google.maps.LatLng(45.131680,-71.433105),
               new google.maps.LatLng(45.143305,-71.433105),
               new google.maps.LatLng(45.201393,-71.400146),
               new google.maps.LatLng(45.232349,-71.441345),
               new google.maps.LatLng(45.236218,-71.411133),
               new google.maps.LatLng(45.236218,-71.383667),
               new google.maps.LatLng(45.265222,-71.361694),
               new google.maps.LatLng(45.305803,-71.290283),
               new google.maps.LatLng(45.294211,-71.257324),
               new google.maps.LatLng(45.269088,-71.251831),
               new google.maps.LatLng(45.245887,-71.218872),
               new google.maps.LatLng(45.251688,-71.194153),
               new google.maps.LatLng(45.249755,-71.161194),
               new google.maps.LatLng(45.247821,-71.130981),
               new google.maps.LatLng(45.276819,-71.114502),
               new google.maps.LatLng(45.301939,-71.095276),
               new google.maps.LatLng(45.305320,-71.092873)
             ];

             var maineMap = new google.maps.Polygon({
               path: maineMapPolygon,
               strokeColor: '#FF0000',
               strokeOpacity: 0.8,
               strokeWeight: 2,
               fillColor: '#FF0000',
               fillOpacity: 0.35
             });

             maineMap.setMap(map);

             google.maps.event.addDomListener(window, "resize", function() {
                var center = map.getCenter();
                google.maps.event.trigger(map, "resize");
                map.setCenter(center);
              });
          };
          var maps_api_key = 'AIzaSyBveEty-RpikAQA1jBQBvlffigo8eq92a4';
          var maps_callback = 'document.initMap';
          var maps_api_url = 'https://maps.google.com/maps/api/js';
          var maps_script_tag = document.createElement('script');
          maps_script_tag.setAttribute('type', 'text/javascript');
          maps_script_tag.setAttribute('src', maps_api_url + '?key=' + maps_api_key + '&callback=' + maps_callback);
          maps_script_tag.setAttribute('defer', '');
          maps_script_tag.setAttribute('async', '');
          document.getElementsByTagName('body')[0].appendChild(maps_script_tag);
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
